import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import GlobalTable from "../../../components/Table/indx";

const targetApi = "advisory";
const pageName = "Advisory";
const initialState = {};
const FilterOptions = [
  { value: "name", label: "Name" },
  { value: "email", label: "Email" },
  { value: "title", label: "Title" },
  { value: "company", label: "Company" },
  { value: "linkUrl", label: "Linkedin" },
  { value: "msg", label: "Message" },
  { value: "createdAt", label: "Date" },
];
const AdvisoryPage: React.FC = () => {
  const { push } = useHistory();
  function columsGenerator(props: any) {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: " name ",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Title",
        dataIndex: "title",
        key: " title  ",
      },

      {
        title: "Company",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "Linkedin",
        dataIndex: "linkUrl",
        key: "linkUrl",
      },
      {
        title: "Message",
        dataIndex: "msg",
        key: "msg",
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date: Date) => <p> {moment(date).format("l")}</p>,
      },
    ];
  }

  return (
    <GlobalTable
      initialState={initialState}
      targetApi={targetApi}
      heads={columsGenerator}
      pageName={pageName}
      isSearch={true}
      FilterOptions={FilterOptions}
    />
  );
};

export default AdvisoryPage;
