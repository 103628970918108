import { useCallback, useMemo, useState } from "react";
import Loader from "../loadingIndicator";
import Pagination from "../pagination";
import { Select, Table } from "antd";
import DeleteModal from "../deleteModal";
import useTabsHooks from "../RequestHandling/useTabsHooks";
import { useHistory } from "react-router-dom";

import Search from "./Search";
import "./style.css";

interface props {
  heads: (props: {}) => object[];
  targetApi: string;
  pageName: string;
  deleteApi?: string;
  formTitle?: string;
  Form?: any;
  initialState: object;
  isSearch?: boolean;
  isExport?: boolean;
  setAdditionalAnalytics?: {};
  FilterOptions?: { value: string; label: string }[];
}

const GlobalTable = ({
  heads,
  targetApi,
  initialState,
  pageName,
  Form,
  formTitle,
  deleteApi,
  isSearch,
  isExport,
  setAdditionalAnalytics,
  FilterOptions,
}: props) => {
  const {
    tabData,
    loading,
    openDelete,
    isAddedOpen,
    tabState,
    pageNum,
    query,
    isEdit,
    setQuery,
    setIsEdit,
    setOpenDelete,
    deletEventHandler,
    paginationHandler,
    setChoosedId,
    handleChange,
    setIsAddedOpen,
    handleAddItem,
    setTabState,
    handleChangeCompanyStatus,
    status,
    setStatus,
    openEditHandler,
    handleOnExport,
    setSelectedFilter,
    selectedFilter,
  } = useTabsHooks(
    targetApi,
    false,
    initialState,
    "",
    "",
    "",
    deleteApi,
    setAdditionalAnalytics
  );
  const { push } = useHistory();

  const [stage, setStage] = useState("");
  const handleViewCompany = useCallback(
    (id: string) => {
      push(`company-details/${id}`);
    },
    [push]
  );
  const handleRespondCompany = useCallback((id: string, response: string) => {
    handleChangeCompanyStatus(id, response);
  }, []);

  const View = useMemo(() => {
    if (isAddedOpen && !!Form)
      return (
        <Form
          tabState={tabState}
          initialState={initialState}
          handleAddItem={handleAddItem}
          handleChange={handleChange}
          setIsAddedOpen={setIsAddedOpen}
          setTabState={setTabState}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      );

    return (
      <div className="h-full flex flex-col overflow-auto   shadow ring-1 ring-black ring-opacity-5">
        {loading ? (
          <div className="w-full h-28 justify-center flex pt-7 ">
            <Loader />
          </div>
        ) : (
          <div>
            <Table
              pagination={false}
              columns={[
                {
                  title: "#",
                  key: "indx",
                  render: (item: any, record: any, indx: any) => (
                    <p>{record?._id}</p>
                  ),
                },
                ...heads({
                  setChoosedId,
                  setOpenDelete,
                  handleViewCompany,
                  handleRespondCompany,
                  openEditHandler,
                }),
              ]}
              dataSource={tabData.data}
            />
          </div>
        )}

        <Pagination
          onPageClick={(pagenum: any) => paginationHandler(pagenum)}
          totalData={tabData.totalCount}
        />
      </div>
    );
  }, [
    isAddedOpen,
    Form,
    tabState,
    initialState,
    handleAddItem,
    handleChange,
    setIsAddedOpen,
    setTabState,
    isEdit,
    setIsEdit,
    loading,
    heads,
    setChoosedId,
    setOpenDelete,
    handleViewCompany,
    handleRespondCompany,
    openEditHandler,
    tabData.data,
    tabData.totalCount,
    pageNum,
    paginationHandler,
  ]);

  const handleViewPage = useMemo(() => {
    return (
      <div className="w-full h-full flex flex-col">
        {!isAddedOpen && (
          <div className="w-full flex flex-col md:flex-row justify-between items-start mb-4 px-8">
            <div className="flex flex-col">
              <p
                className="font-bold text-2xl text-[var(--black-color-11)] inline"
                children={pageName}
              />
            </div>
            <div className="flex items-center mt-2 md:mt-0">
              {isSearch && FilterOptions && (
                <Search
                  query={query}
                  setQuery={setQuery}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  options={FilterOptions}
                />
              )}
              {isExport && (
                <button
                  onClick={() => handleOnExport(pageName)}
                  className="py-4 px-6 ml-4 flex justify-center items-center font-medium text-sm text-[#fff] bg-[#1E70FC] hover:shadow-lg h-9 rounded-md shadow-md"
                  children="Export"
                />
              )}

              {/* Add Button */}
              {!!Form && (
                <button
                  onClick={() => setIsAddedOpen((old) => !old)}
                  className="py-4 px-6 ml-4 flex justify-center items-center font-medium text-xs sm:text-sm text-[#fff] bg-[#1E70FC]  hover:shadow-lg h-9 rounded-md shadow-md "
                  children={`Add ${pageName}`}
                />
              )}
            </div>
          </div>
        )}
        {pageName === "Companies" && (
          <Select
            placeholder="All Stages"
            showArrow
            style={{
              width: 150,
              height: 40,
              padding: 7,
              marginBottom: 20,
              marginLeft: 30,
            }}
            value={status ? status : ""}
            onChange={(e) => setStatus(e)}
            onClear={() => setStatus("")}
          >
            <Select.Option value={"all"}>All</Select.Option>
            <Select.Option value={"accepted"}>Accepted</Select.Option>
            <Select.Option value={"rejected"}>Rejected</Select.Option>
            <Select.Option value={"pending"}>Pending</Select.Option>
          </Select>
        )}

        <DeleteModal
          onDelete={deletEventHandler}
          show={openDelete}
          onClose={() => setOpenDelete(false)}
          title={pageName}
        />

        {View}
      </div>
    );
  }, [
    isAddedOpen,
    pageName,
    isSearch,
    query,
    setQuery,
    isExport,
    Form,
    status,
    deletEventHandler,
    openDelete,
    View,
    handleOnExport,
    setIsAddedOpen,
    setStatus,
    setOpenDelete,
    FilterOptions,
    selectedFilter,
    setSelectedFilter,
  ]);

  return <>{handleViewPage}</>;
};

export default GlobalTable;
