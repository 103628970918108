import { FC, useState, useCallback, useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PaginationProps } from "./index.interface";
import "./style.css";

const Pagination: FC<PaginationProps> = ({ onPageClick, totalData }) => {
  const [activePage, setPageActive] = useState(1);
  let ListOfPagesButtons = [] as any;

  const handlePageClicked = useCallback(
    (pageNum: number) => {
      onPageClick(pageNum);
      setPageActive(pageNum);
      ListOfPagesButtons = [];
    },
    [onPageClick]
  );
  const PagesCount =
    totalData % 10 === 0
      ? totalData / 10
      : totalData / 10 + 1 - (totalData % 10) / 10;

  const handlePages = useMemo(() => {
    for (let i = 1; i < PagesCount + 1; i++) {
      if (activePage === i)
        ListOfPagesButtons.push(
          <span
            key={i}
            onClick={() => handlePageClicked(i)}
            aria-current="page"
            className=" rounded-md bg-[#1E70FC0f] border-[#1E70FC] mx-1 text-[#1E70FC] relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          >
            {i}
          </span>
        );
      else
        ListOfPagesButtons.push(
          <span
            onClick={() => handlePageClicked(i)}
            key={i}
            className="border-[#1E70FC] text-[#1E70FC] rounded-md mx-1 hover:bg-[#1E70FC0f] relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          >
            {i}
          </span>
        );
    }
    return <div className="mr-2">{ListOfPagesButtons}</div>;
  }, [activePage, ListOfPagesButtons, PagesCount, handlePageClicked]);

  return (
    <div className="bg-transparent py-3 flex items-center justify-between   sm:px-6">
      {/* <div className="flex-1 flex justify-between sm:hidden">
        <a
          onClick={() => {
            activePage > 0 && handlePageClicked(activePage - 1);
          }}
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          onClick={() => {
            activePage < PagesCount && handlePageClicked(activePage + 1);
          }}
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div> */}
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span className="relative inline-flex mr-2 items-center px-2 py-2 rounded-md border border-gray-300  text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => {
                  activePage > 1 && handlePageClicked(activePage - 1);
                }}
              />
            </span>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}

            {handlePages}
            <span className="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300  text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointeu">
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => {
                  activePage < PagesCount && handlePageClicked(activePage + 1);
                }}
              />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
