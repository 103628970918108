import { get, post} from '../../utilis/requests';
import { getFromStorage } from '../../utilis/localStorage';


export const getUserData = async () => {
  const user = getFromStorage('@userAdmin');
  try {
    const { data } = await get('/user/me', user.token);
    return data;
  } catch (error) {
    return false;
  }
};

export const logoutApi = async () => {
  const user = getFromStorage('@userAdminExits');
  try {
    const { data } = await post('/auth/logout', {},user.token);
    return data;
  } catch (error) {
    return false;
  }
};
