import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  editHandler,
  getHandlerNoPage,
} from "../../components/RequestHandling/api";
import userStore from "../../userStore/context";
import { useHistory } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Notification from "@material-ui/icons/Notifications";
import { Button } from "@mui/material";

interface TNotification {
  _id: string;
  companyId: string;
  createdAt: string;
  isRead: boolean;
  title: string;
  updatedAt: string;
  userId: string;
  notificationType: "acquisition" | "company";
  __v: number;
}

const AlarmButton: React.FC = () => {
  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const { push } = useHistory();

  const {
    userState: { token },
  } = useContext(userStore);

  async function getNotifications() {
    const res = await getHandlerNoPage("notification", token);
    setNotifications(res?.data || []);
  }

  async function markAsRead(item: TNotification) {
    await editHandler("notification", { isRead: item.isRead }, token, item._id);
    handleClose();

    push(
      `/${
        item.notificationType === "acquisition"
          ? "acquisition"
          : "company-details"
      }/${item.companyId}`
    );
  }

  useEffect(() => {
    !!open && getNotifications();
  }, [open]);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        <Notification className={styles.alarmButton} />
      </Button>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className={styles.modal}>
            {notifications.map((notification) => (
              <div
                key={notification._id}
                onClick={() => markAsRead(notification)}
                className={styles.notificationItem}
                style={{ background: notification.isRead ? "#f0f0f0" : "" }}
              >
                <div className={styles.notificationText}>
                  {notification.title}
                </div>
                <div className={styles.notificationDate}>
                  {new Date(notification.createdAt).toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </Popover>
      </>
    </div>
  );
};

export default AlarmButton;
