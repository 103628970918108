import Home from "../src/appRoutes";
import "./App.css";
import "./index.css";
import UserStore from "./userStore";

const App = () => {
  return (
    //@ts-ignore
    <UserStore>
      <Home />
    </UserStore>
  );
};

export default App;
