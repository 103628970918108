import React from "react";
import message from "antd/es/message";
import { getUserData, logoutApi } from "./api";
import userContext from "../../userStore/context";

import { saveToStorage } from "../../utilis/localStorage";
import { useHistory } from "react-router-dom";
const useGetInfoHook = () => {
  const { userState, setUserContext } = React.useContext(userContext);
  const history = useHistory();
  const [state, setState] = React.useState({
    name: "",
    email: "",
    photo: {
      url: "",
      public_id: "",
    },
    phoneNumber: "",
  });
  const logoutHandler = async () => {
    const response = await logoutApi();
    if (response) {
      localStorage.removeItem("@userAdminExits");
      history.push("signin");
    }
  };
  const handleGetUserData = React.useCallback(async () => {
    const response = await getUserData();
    if (response && !!Object.keys(response?.user).length) {
      setState({
        name: response.user.name,
        email: response.user.email,
        phoneNumber: response.user.phoneNumber,
        photo: {
          url: response?.user?.photo?.url,
          public_id: "",
        },
      });
    }
  }, [setState]);

  React.useEffect(() => {
    // handleGetUserData();
  }, [handleGetUserData]);
  return {
    state,
    logoutHandler,
  };
};

export default useGetInfoHook;
