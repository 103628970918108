import React, { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface modalInterface {
  show: any;
  onClose: any;
  title: string;
  onDelete: () => void;
}

const DeleteModal: FC<modalInterface> = ({
  show,
  onClose,
  title,
  onDelete,
}) => {
  
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto px-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#1B1B1B] px-10 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white text-center"
                    >
                      Delete {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-[#ffffffb3] text-center">
                        Are you sure you want to delete this {title}?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 sm:mt-6 flex items-center justify-center">
                  <button
                    type="button"
                    className="inline-flex justify-center  bg-transparent px-4 py-2 text-base font-medium text-[#FFFFFF] shadow-sm focus:outline-none sm:text-sm"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center bg-transparent px-4 py-2 text-base font-medium text-[#FF4648] shadow-sm focus:outline-none sm:text-sm"
                    onClick={() => {
                      onClose();
                      onDelete();
                    }}
                  >
                    Delete
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteModal;
