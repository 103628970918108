import { Dispatch, SetStateAction, useMemo } from "react";
import { SearchCircleIcon } from "@heroicons/react/solid";
import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const dateKeys = ["personalInformation.dateFounded", "createdAt", "updatedAt"];
interface search {
  query: string | Date;
  setQuery: Dispatch<SetStateAction<string>>;
  selectedFilter: string;
  setSelectedFilter: Dispatch<SetStateAction<string>>;
  options: { value: string; label: string }[];
}

const Search: React.FC<search> = ({
  query,
  setQuery,
  setSelectedFilter,
  selectedFilter,
  options,
}): JSX.Element => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value as string);
    setQuery("");
  };
  const isDateFilter = dateKeys.includes(selectedFilter);
  const handleSearchView = useMemo(() => {
    return (
      <div className="flex gap-4">
        <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Search Criteria
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFilter || undefined}
              label="Search Criteria"
              onChange={handleChange}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!isDateFilter && typeof query === "string" ? (
          <div className=" border border-gray-200 flex items-center justify-between rounded-md shadow-sm">
            <label htmlFor="search" className="cursor-pointer">
              <div className="flex items-center px-2 w-full">
                <SearchCircleIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  onClick={() => setQuery("")}
                />
              </div>
            </label>

            <input
              type="text"
              name="search"
              id="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="cursor-text w-full border-none focus:ring-none focus:outline-none sm:block text-sm px-2"
              placeholder="Search"
            />
          </div>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date picker"
              onChange={(newValue: string | null) =>
                setQuery(newValue as string)
              }
            />
          </LocalizationProvider>
        )}
      </div>
    );
  }, [query, setQuery, isDateFilter]);

  return <>{handleSearchView}</>;
};

export default Search;
