import { createContext } from "react";

import { State, StoreApi } from "./index.interface";

export const initialState: State = {
  name: "",
  email: "",
  token: "",
  id: "",
};

export default createContext<StoreApi>({
  userState: initialState,
  //@ts-ignore
  setUserContext: (state: State) => state,
});
