import { useCallback, useContext, useEffect, useRef, useState } from "react";
import XLSX from "xlsx";
import userStore from "../../userStore/context";
import {
  addHandler,
  deleteHandler,
  editHandler,
  getHandler,
  getCompaniesHandler,
  getHandlerNoPage,
  openItemById,
  patchStatusCompany,
  patchCompanyInfo,
} from "./api";
import { useHistory } from "react-router-dom";
import { message } from "antd";

const useTabsHooks = (
  targetApi: String,
  onlyDetails?: boolean,
  initialState?: Object,
  additionalTargetApi?: any,
  postAPI?: any,
  usersApi?: any,
  deleteApi?: any,
  setAnalyticsData?: any
) => {
  const { goBack } = useHistory();
  const [loading, setLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(true);

  const [isAddedOpen, setIsAddedOpen] = useState(false);
  const [tabActive, setTabActive] = useState(1);
  const [pageNum, setPageNum] = useState<number>(1);
  const [tabData, setTabData] = useState({
    data: [
      {
        _id: "U1",
        userName: "Ahmed",
        createdAt: "1 june 2023",
        phoneNumber: "01234566777",
        email: "test@any.com",
        country: "Egypt",
        city: "Cairo",
        startup: [{ name: "test Company1" }, { name: "test Company2" }],
      },
    ],
    totalCount: 0,
    stats: [],
    newData: [],
  });

  /// vars for company details only and editing company info
  const compRef = useRef();
  const [companyInfo, setCompanyInfo] = useState<any>({});
  const [companyInfoHolder, setCompanyInfoHolder] = useState<any>({});
  const [isEditCompany, setIsEditCompany] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [fileObject, setFileObject] = useState({
    name: "",
    url: "",
  });
  // const fileNames = Object.keys(company?.supportingDocument);
  // const fileValues = Object.values(company?.supportingDocuments);
  const [fileNames, setFileNames] = useState<any>([]);
  const [fileValues, setFileValues] = useState<any>([]);

  const [additionalTabData, setAdditionalTabData] = useState({
    data: [],
    noOfItems: 0,
  });

  const [query, setQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [usersApiData, setUsersApiData] = useState({
    data: [],
    noOfItems: 0,
  });
  const [status, setStatus] = useState("all");

  const [choosedId, setChoosedId] = useState(null);
  const [request, setRequestId] = useState(null);
  const [tabState, setTabState] = useState<any>(initialState || {});
  const [openDelete, setOpenDelete] = useState(false);

  const {
    location: { pathname },
  } = useHistory();

  const {
    userState: { token },
  } = useContext(userStore);

  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  let list = [];
  let formattedList = () => {
    tabData?.data?.forEach((item) => {
      list.push(
        //@ts-ignore
        item?.personalInformation,
        //@ts-ignore
        item?.businessOverview,
        //@ts-ignore
        item?.founderInformation
      );
    });
  };
  formattedList();

  const handleOnExport = (pageName: string) => {
    const wb = XLSX.utils.book_new();
    const ws =
      pageName === "Companies"
        ? XLSX.utils.json_to_sheet(list)
        : XLSX.utils.json_to_sheet(tabData?.data);

    XLSX.utils.book_append_sheet(wb, ws, pageName);
    XLSX.writeFile(wb, `${pageName}.xlsx`);
  };

  const handleChangeTab = useCallback((tabIndex) => {
    setTabActive(tabIndex);
  }, []);

  const handleChange = useCallback(
    (name: string) => (val: any) => {
      setTabState((oldState: any) => ({
        ...oldState,
        [name]: val.target ? val.target.value : val,
      }));

      // setTabState((oldState) => {
      //   let item = oldState[0];
      //   return { ...item, [name]: val.target ? val.target.value : val };
      // });
    },
    [setTabState]
  );

  // fetch Companies
  const fetchCompaniesHandler = useCallback(
    async (pageNum: Number) => {
      setLoading(true);
      setTabData(await getCompaniesHandler(targetApi, pageNum, token, status));
      setLoading(false);
    },
    [status]
  );

  //// fetch Sellers / Buyers
  const fetchHandler = useCallback(
    async (pageNum: Number) => {
      setLoading(true);
      setTabData(await getHandler(targetApi, pageNum, token));
      setLoading(false);
    },
    [targetApi, token]
  );
  const fetchHandlerSearch = useCallback(
    async (pageNum) => {
      setLoading(true);
      setTabData(
        await getHandler(targetApi, pageNum, token, query, selectedFilter)
      );
      setLoading(false);
    },
    [targetApi, token, query]
  );

  const fetchAdditionalHandler = async () => {
    setCompanyLoading(true);
    const response = await getHandlerNoPage(additionalTargetApi, token);
    setAdditionalTabData(response);
    if (additionalTargetApi.includes("company?_id")) {
      setCompanyInfo(response?.data[0]);
      setCompanyInfoHolder(response?.data[0]);
      setFileNames(Object.keys(response?.data[0].supportingDocuments));
      setFileValues(Object.values(response?.data[0].supportingDocuments));
    }
    setCompanyLoading(false);
  };

  const onDeleteCompanyFile = useCallback(
    (fileName: string) => {
      let tempFiles = { ...companyInfo.supportingDocuments };
      delete tempFiles[fileName];
      setFileNames(Object.keys(tempFiles));
      setFileValues(Object.values(tempFiles));
      setCompanyInfo((old) => {
        return {
          ...old,
          supportingDocuments: tempFiles,
        };
      });
    },
    [companyInfo]
  );

  const toggleModal = useCallback(() => {
    setAddModal((old) => !old);
    setFileObject({
      url: "",
      name: "",
    });
  }, []);
  function handleSecureUrlFile(img: any) {
    setFileObject((old) => {
      return {
        ...old,
        url: img,
      };
    });
  }
  const handleChangeFileName = useCallback((val) => {
    setFileObject((old) => {
      return {
        ...old,
        name: val,
      };
    });
  }, []);
  const onAddCompanyFile = useCallback(() => {
    let tempFiles = { ...companyInfo.supportingDocuments };
    tempFiles[fileObject.name] = fileObject.url;
    setCompanyInfo((old) => {
      return {
        ...old,
        supportingDocuments: tempFiles,
      };
    });
    setFileNames(Object.keys(tempFiles));
    setFileValues(Object.values(tempFiles));
    toggleModal();
  }, [fileObject, companyInfo, toggleModal]);

  const editCompanyHandler = useCallback(async () => {
    setLoading(true);
    let companyId = targetApi.split("=")[1];

    let companyInfoForApi = { ...companyInfo };
    const res = await patchCompanyInfo(
      `company/${companyId}`,
      companyInfoForApi,
      token
    );
    if (res.data.message === "Company updated successfully") {
      fetchAdditionalHandler();
    }

    setLoading(false);
  }, [companyInfo, fetchAdditionalHandler, targetApi, token]);

  const toggleEditCompany = useCallback(
    () =>
      setIsEditCompany((old) => {
        if (old === true) {
          editCompanyHandler();
        }

        return !old;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editCompanyHandler]
  );

  const toggleCancelEditingCompany = useCallback(() => {
    setCompanyInfo(companyInfoHolder);
    setFileNames(Object.keys(companyInfoHolder?.supportingDocuments));
    setFileValues(Object.values(companyInfoHolder?.supportingDocuments));
    setIsEditCompany(false);
  }, [companyInfoHolder]);
  const onChangeCompanyInfo = useCallback(
    (type: string, name, val, subType) => {
      if (type === "personalInformation") {
        setCompanyInfo((old) => {
          return {
            ...old,
            personalInformation: { ...old.personalInformation, [name]: val },
          };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
      if (type === "founderInformation") {
        setCompanyInfo((old) => {
          return {
            ...old,
            founderInformation: { ...old.founderInformation, [name]: val },
          };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
      if (type === "businessOverview") {
        setCompanyInfo((old) => {
          return {
            ...old,
            businessOverview: { ...old.businessOverview, [name]: val },
          };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
      if (type === "financialsAnalysis") {
        if (subType === "none") {
          setCompanyInfo((old) => {
            return {
              ...old,
              financialsAnalysis: { ...old.financialsAnalysis, [name]: val },
            };
          });
        } else {
          setCompanyInfo((old) => {
            return {
              ...old,
              financialsAnalysis: {
                ...old.financialsAnalysis,

                [subType]: {
                  ...old.financialsAnalysis[subType],
                  [name]: val,
                },
              },
            };
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    },
    []
  );
  // handle change company status "pending" "approved" "rejected"
  const handleChangeCompanyStatus = async (id, status) => {
    const response = await patchStatusCompany(
      `company/updaterequest/${id}`,
      {
        status: status,
        isVerified: status === "accepted" ? true : false,
      },
      token
    );
    fetchCompaniesHandler(pageNum);
    // setAdditionalTabData(response);
  };
  // Requests tab >> add crypto >> User name to get users list
  const fetchusersApiInRequestTab = async () => {
    const response = await getHandlerNoPage(usersApi, token);
    setUsersApiData(response);
  };

  const deletEventHandler = () => {
    deleteHandler(!!deleteApi ? deleteApi : targetApi, choosedId, token).then(
      (res) => {
        res && fetchHandler(pageNum);
        res && pathname.includes("Companies") && fetchCompaniesHandler(pageNum);
      }
    );
  };

  const getAnalyticsData = async () => {
    // setLoading(true);
    const res = await getHandler("admin/count-data", 0, token, query);
    if (res) {
      setAnalyticsData({ ...res.data, loading: false });
    }
    // setLoading(false);
  };
  const paginationHandler = (pageNum: number) => {
    if (
      targetApi === "seller" ||
      targetApi === "buyer" ||
      targetApi === "advisory" ||
      targetApi === "admin"
    ) {
      if (query === "") fetchHandler(pageNum);
      else {
        fetchHandlerSearch(pageNum);
      }
    } else {
      fetchCompaniesHandler(pageNum);
    }
    setPageNum(pageNum);
  };

  const handleAddItem = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      if (isEdit === true) {
        const response = await editHandler(
          targetApi,
          tabState,
          token,
          tabState._id
        );
        if (response) {
          setTabState(initialState);
          fetchHandler(pageNum);

          setIsEdit(false);
          setIsAddedOpen(false);
          pathname.includes("Companies") ||
            (pathname.includes("company-details") &&
              fetchCompaniesHandler(pageNum));
          setLoading(false);
        }
      } else {
        const response = await addHandler(targetApi, tabState, token);
        if (response) {
          setTabState(initialState);
          setIsAddedOpen(false);
          fetchHandler(pageNum);
          pathname.includes("Companies") ||
            (pathname.includes("company-details") &&
              fetchCompaniesHandler(pageNum));
          setLoading(false);
        }
        setLoading(false);
      }

      setLoading(false);
    },
    [tabState, isEdit, query]
  );

  const openEditHandler = useCallback(
    async (item) => {
      setLoading(true);
      setIsEdit(true);
      setIsAddedOpen(true);
      const response = await openItemById(targetApi, item._id, token);

      if (response.data) {
        setTabState(response.data);
        setLoading(false);
      } else {
        message.error("error");
        setLoading(false);
      }
    },
    [isEdit]
  );

  useEffect(() => {
    fetchHandlerSearch(1);
  }, [query, fetchHandlerSearch, selectedFilter]);
  useEffect(() => {
    if (token) {
      if (
        pathname.includes("Companies") ||
        pathname.includes("company-details")
      ) {
        !onlyDetails && fetchCompaniesHandler(pageNum);
        fetchAdditionalHandler();
      } else {
        fetchHandler(pageNum);
        if (targetApi === "company/requested") getAnalyticsData();
      }
    }
  }, [status, token]);

  return {
    loading,
    tabState,
    isAddedOpen,
    tabData,
    openDelete,
    tabActive,
    additionalTabData,
    usersApiData,
    pageNum,
    query,
    isEdit,
    setIsEdit,
    setQuery,
    setTabState,
    setIsAddedOpen,
    handleChange,
    setChoosedId,
    paginationHandler,
    deletEventHandler,
    handleAddItem,
    fetchAdditionalHandler,
    fetchusersApiInRequestTab,
    setOpenDelete,
    setRequestId,
    handleChangeTab,
    handleChangeCompanyStatus,
    handleBack,
    status,
    setStatus,
    openEditHandler,
    handleOnExport,
    companyInfo,
    companyLoading,
    onChangeCompanyInfo,
    isEditCompany,
    toggleEditCompany,
    toggleCancelEditingCompany,
    fileNames,
    fileValues,
    onDeleteCompanyFile,
    handleChangeFileName,
    handleSecureUrlFile,
    showAddModal,
    onAddCompanyFile,
    toggleModal,
    fileObject,
    editCompanyHandler,
    setSelectedFilter,
    selectedFilter,
  };
};

export default useTabsHooks;
