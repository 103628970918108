import React, { lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../layout";
import RouteWrapper from "./RouteWrapper";
import LoadingPlaceholder from "../components/loadingIndicator";
import Advisorypage from "../pages/navPages/advisory";
const Signin = lazy(
  () =>
    import(
      /* webpackChunkName: "NewPasswordPage" */
      /* webpackMode: "lazy" */
      "../pages/auth"
    )
);
const BaseScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "NewPasswordPage" */
      /* webpackMode: "lazy" */
      "../pages/baseScreen"
    )
);
const ForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordPage" */
      /* webpackMode: "lazy" */
      "../pages/auth/ForgetPassword"
    )
);

const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyNamePage" */
      /* webpackMode: "lazy" */
      "../pages/auth/resetPassword"
    )
);

const Home = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCodePage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/overview"
    )
);

const Acquisition = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCodePage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/viewAcquisition/index"
    )
);

const Companies = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/companies/views/companiesList"
    )
);
const CompanyDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/companies/views/companyDetails"
    )
);
const Logs = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/logs"
    )
);
const Sellers = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/sellers"
    )
);
const Buyers = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/buyers"
    )
);
// const ViewSeller = lazy(
//   () =>
//     import(
//       /* webpackChunkName: "ConfirmCreationPage" */
//       /* webpackMode: "lazy" */
//       "../pages/navPages/sellers/viewSeller"
//     )
// );
// const ViewBuyer = lazy(
//   () =>
//     import(
//       /* webpackChunkName: "ConfirmCreationPage" */
//       /* webpackMode: "lazy" */
//       "../pages/navPages/buyers/viewBuyer"
//     )
// );

const Events = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/events"
    )
);
const Admins = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/admins"
    )
);

const Notifications = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmCreationPage" */
      /* webpackMode: "lazy" */
      "../pages/navPages/notifications"
    )
);

const OTP = lazy(
  () =>
    import(
      /* webpackChunkName: "NewPasswordPage" */
      /* webpackMode: "lazy" */
      "../pages/auth/otp"
    )
);

const AppRoutes = () => (
  <BrowserRouter>
    <React.Suspense fallback={<LoadingPlaceholder />}>
      <Switch>
        <Route path="/" component={BaseScreen} exact />
        <Route path="/base" component={BaseScreen} exact />
        <Route path="/signin" component={Signin} exact />
        <Route path="/resetpassword" component={ResetPassword} exact />
        <Route path="/forgetpassword" component={ForgotPassword} exact />
        <Route path="/otp" component={OTP} exact />
        <RouteWrapper path="/Overview" component={Home} layout={Layout} />
        <RouteWrapper
          path="/acquisition/:companyId"
          component={Acquisition}
          layout={Layout}
        />
        <RouteWrapper
          path={`/Companies`}
          component={Companies}
          layout={Layout}
        />
        <RouteWrapper
          path={`/company-details/:companyId`}
          component={CompanyDetails}
          layout={Layout}
        />
        <RouteWrapper path={`/Logs`} component={Logs} layout={Layout} />
        <RouteWrapper
          path={`/Sellers`}
          component={Sellers}
          layout={Layout}
          exact
        />
        {/* <RouteWrapper
          path={`/Sellers/:sellerId`}
          component={ViewSeller}
          layout={Layout}
          exact
        /> */}
        <RouteWrapper
          path={`/Buyers`}
          component={Buyers}
          layout={Layout}
          exact
        />
        {/* <RouteWrapper
          path={`/Buyers/:buyerId`}
          component={ViewBuyer}
          layout={Layout}
          exact
        /> */}

        <RouteWrapper
          path={`/Admins`}
          component={Admins}
          layout={Layout}
          exact
        />
        <RouteWrapper
          path={`/Advisory`}
          component={Advisorypage}
          layout={Layout}
          exact
        />
        <RouteWrapper path={`/events`} component={Events} layout={Layout} />

        <RouteWrapper
          path={`/notifications`}
          component={Notifications}
          layout={Layout}
        />

        {/* <RouteWrapper path="/profile" component={Profile} layout={Layout} /> */}

        {/* <Route path="*" component={NotFound} /> */}
      </Switch>
    </React.Suspense>
  </BrowserRouter>
);

export default AppRoutes;
