// @ts-nocheck
import React, { FC, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import userContext from '../../userStore/context';

import RouteWrapperInterface from './interface';

const RouteWrapper: FC<RouteWrapperInterface> = ({
  component: Component,
  layout: Layout,
  ...rest
}): JSX.Element => {
  const { userState } = useContext(userContext);

  const isThereUser = Object.values(userState);
  return Boolean(isThereUser) ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to={{ pathname: '/signin' }} />
  );
};

export default React.memo(RouteWrapper);
